import React, { Component, createRef } from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

const ProjectLink = styled(GatsbyLink)`
  font-family:  'Shapiro-55MiddleText';
  font-size: 6.25rem;
  line-height: 1.18;
  z-index: 2;
  position: relative;
  transition: opacity 0.3s ease-in-out;
  

  @media ${ props => props.theme.device.sm } {
    font-size: 2.5rem;
  }
`

const Project = styled.div`
  display: block;
  padding-bottom: 3.75rem;
  
  @media ${ props => props.theme.device.sm } {
    padding-bottom: 1.5rem;
  }

  &:last-child {
    padding-bottom: 0;
  }

  ${ props => props.theme.hover } {
    &:hover {
      ${ ProjectLink } {
        opacity: 1 !important;
      }
    }
  }
`

const Container = styled.div`
  width: 100%;
  z-index: 1;

  ${ props => props.theme.hover } {
    &:hover {
      ${ ProjectLink } {
        opacity: 0.2;
      }
    }
  }
`

const ProjectImage = styled.div`
  width: 500px;
  height: 333px;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  z-index: 0;
  pointer-events: none;

  > div {
    width: 100%;
    height: 100%;
    transition: background-image 0.5s ease-in-out;
    background-size: cover;
    background-repeat: no-repeat;
    backface-visibility: hidden;
    transform: perspective(1000px);
  }

  &.project-image-visible {
    opacity: 1;
    visibility: visible;
  }
`

const BackgroundImagePreloader = styled.div`
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  background-image: ${ props => props.images.map(image => `url(${ image })`).join(',') };
`

class ProjectList extends Component {
  constructor (props) {
    super(props)
    this.projectImageRef = createRef()
    this.state = {
      imagesPreloaded: false
    }
  }

  setHoverImage = ({ clientX, clientY }) => {
    this.projectImageRef.current.style.transform = `translate3d(${ clientX - 250 }px, ${ clientY - 156.125 }px, 0)`
  }

  showHoverImage = () => {
    this.projectImageRef.current.classList.add('project-image-visible')
  }

  hideHoverImage = () => {
    this.projectImageRef.current.classList.remove('project-image-visible')
  }

  setCurrentHoverImage = hoverImage => {
    this.projectImageRef.current.children[0].style.backgroundImage = `url(${ hoverImage && hoverImage.fluid.src })`
  }

  render () {
    const { projects } = this.props

    return (
      <Container
        onMouseMove={this.setHoverImage}
        onMouseOver={this.setHoverImage}
        onMouseEnter={this.showHoverImage}
        onMouseLeave={this.hideHoverImage}
      >
        <BackgroundImagePreloader images={projects.map(({ hoverImage }) => hoverImage && hoverImage.fluid.src)}/>
        {projects.map(({ displayName, clientName, slug, hoverImage }, index) => (
          <Project
            key={index}
            onMouseEnter={() => this.setCurrentHoverImage(hoverImage)}>
            <ProjectLink to={slug}>
              {displayName || clientName}
            </ProjectLink>
          </Project>
        ))}
        <ProjectImage ref={this.projectImageRef}>
          <div></div>
        </ProjectImage>
      </Container>
    )
  }
}

export default ProjectList
