import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby'

const Container = styled.div`
  font-size: 3rem;
  line-height: 1.1875;
  margin-bottom: 4rem;

  @media ${ props => props.theme.device.sm } {
    font-size: 1.125rem;
    line-height: 1.39;
    margin-bottom: 5rem;
    margin-top: 3.25rem;
  }
`

const Link = styled.span`
  position: relative;
  z-index: 1;
  text-decoration: underline;
`

const LinkBackground = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transition: transform 0.6s ease-in-out;
  transform-origin: right;
  z-index: 0;

  .category-active & {
    background: none;
    z-index: 0;
    transform: scaleX(1);
  }
`

const LinkBackgroundInner = styled.span`
  display: inline-block;
  margin: auto;
  position: absolute;
  height: 27px;
  top: 2.5px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 99%;
  background-color: ${ props => props.theme.dark.accent };

  .light-theme & {
    background-color: ${ props => props.theme.light.accent };
  }

  @media ${ props => props.theme.device.sm } {
    height: 10px;
    top: 0.5px;
  }
`

const LinkContainer = styled(GatsbyLink)`
  position: relative;
  white-space: pre;

  ${ props => props.theme.hover } {
    &:not(.category-active):hover {
      ${ LinkBackground } {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }
`

const AboutText = () => {
  const {
    datoCmsHome: {
      aboutText,
      featuredCategories
    },
    allDatoCmsCategory: {
      nodes: heroCategories
    }
  } = useStaticQuery(CategoriesQuery)

  const [artDirection, typography] = heroCategories

  return (
    <Container>
      {'We create '}
      {featuredCategories.map(({ slug, displayName, name }, index) => {
        let joinCharacter

        if (index === featuredCategories.length - 2) {
          joinCharacter = ' and also '
        } else if (index === featuredCategories.length - 1) {
          joinCharacter = ''
        } else {
          joinCharacter = ', '
        }

        return (
          <Fragment key={index}>
            <LinkContainer to={slug} activeClassName="category-active">
              <Link>{displayName || name}</Link>
              <LinkBackground>
                <LinkBackgroundInner/>
              </LinkBackground>
            </LinkContainer>{joinCharacter}
          </Fragment>
        )
      })}
      {'. Our '}
      <LinkContainer to={artDirection.slug} activeClassName="category-active">
        <Link>{artDirection.displayName || artDirection.name}</Link>
        <LinkBackground>
          <LinkBackgroundInner/>
        </LinkBackground>
      </LinkContainer>
      {' focuses strongly on '}
      <LinkContainer to={typography.slug} activeClassName="category-active">
        <Link>{typography.displayName || typography.name}</Link>
        <LinkBackground>
          <LinkBackgroundInner/>
        </LinkBackground>
      </LinkContainer>
      {`. ${ aboutText }`}
    </Container>
  )
}

const CategoriesQuery = graphql`
  query CategoriesQuery {
    datoCmsHome {
      aboutText
      featuredCategories {
        name
        displayName
        slug
      }
    }
    allDatoCmsCategory(filter: {name: {regex: "/typography|art direction/"}}) {
      nodes {
        displayName
        name
        slug
      }
    }
  }
`

export default AboutText
